import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';  
import { get, post, del } from 'aws-amplify/api';
import './EmployeeDetails.css';

Amplify.configure(awsmobile);

function capitalize(str) {
  if (!str || typeof str !== 'string') return ''; // Check if the string is valid
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function getLinkColor(score) {
  switch (score) {
      case 1: return '#2c3e50';  // Vibrant Green
      case 3: return '#2980b9';  // Bright Blue
      case 5: return '#f39c12';  // Vivid Yellow
      case 7: return '#d35400';  // Rich Orange
      case 10: return '#c0392b';  // Strong Red
      default: return '#2c3e50';  // Soft Blue-Grey, less attention-grabbing
  }
}

// Function to determine the color based on the score
function getColorByScore(score) {
  if (score > 25) {
      return '#c0392b'; // Dark Red
  } else if (score >= 20 && score <= 24) {
      return '#d35400'; // Dark Purple
  } else if (score >= 15 && score <= 19) {
      return '#f39c12'; // Brown
  } else if (score >= 10 && score <= 14) {
      return '#2980b9'; // Olive
  } else {
      return '#2c3e50'; // Dark Blue for scores less than 10
  }
}

function EmployeeDetails() {
  const { id } = useParams();  // Get the employee ID from the URL
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [newTask, setNewTask] = useState({ name: '', rationale: '', category: '' });

  const categories = [
        { id: 5, name: 'Critical', score: 10 },
        { id: 2, name: 'Moderate', score: 3 },
        { id: 3, name: 'Severe', score: 5 },
        { id: 1, name: 'Normal', score: 1 },
        { id: 4, name: 'Very Serious', score: 7 },
  ];

  useEffect(() => {
    // Function to fetch employee details by ID
    const fetchEmployeeById = async (id) => {
      try {
        const restOperation = get({
            apiName: 'employees', 
            path: `/employees/${id}`
        });
        const { body } = await restOperation.response; 
        const jsonResponse = await body.json();
        setEmployee(jsonResponse.data);  
        setLoading(false);
      } catch (err) {
        console.error('Fetching employee failed:', err);
        setError('Failed to fetch employee');
        setLoading(false);
      }
    };

    fetchEmployeeById(id);
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const handleAddTask = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    try {
      newTask["assignee_id"] = parseInt(id);
      newTask["assignee_name"] = employee["name"];
      const category = categories.find(cat => cat.name === newTask["category"]);
      newTask["score"] = category["score"];
      newTask["type"] = "issue";
      newTask["category_id"] = category["id"].toString();
  
      console.log('New task:', newTask);
  
      const restOperation = post({
        apiName: 'incidents',
        path: `/incidents`,
        options: {
          body: newTask
        }
      });
  
      const { body } = await restOperation.response;
      const addedTask = await body.json();
  
      // Increment the employee's score
      const newScore = employee.score + newTask["score"];
  
      // Update the employee's state with the new task and new score
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        tasks: [addedTask.item, ...prevEmployee.tasks],
        score: newScore // Update the score
      }));
  
      // Reset the new task form
      setNewTask({ name: '', rationale: '', category: '' });
  
    } catch (err) {
      console.error('Adding task failed:', err);
      setError('Failed to add task');
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      // Find the task to be deleted
      const taskToDelete = employee.tasks.find(task => task.id === taskId);
  
      // Call the delete API
      await del({
        apiName: 'incidents',
        path: `/incidents/${taskId}`,
      });
  
      // Subtract the task's score from the employee's score
      const newScore = employee.score - taskToDelete.score;
  
      // Update the employee's state with the new task list and new score
      setEmployee((prevEmployee) => ({
        ...prevEmployee,
        tasks: prevEmployee.tasks.filter(task => task.id !== taskId),
        score: newScore
      }));
    } catch (err) {
      console.error('Deleting task failed:', err);
      setError('Failed to delete task');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!employee) return <p>No employee found</p>;

  return (
    <div className="employees-content">
      <h2>{employee.name} - <span style={{ color: getColorByScore(employee.score) }}> Points: {employee.score} </span> </h2>
      <h3>Add New Issue</h3>
      <form className="new-task-form" onSubmit={handleAddTask}>
        <input 
          type="text"
          name="name"
          value={newTask.name}
          onChange={handleInputChange}
          placeholder="Issue Name"
          required // HTML5 form validation
        />
        <textarea 
          name="rationale"
          value={newTask.rationale}
          onChange={handleInputChange}
          placeholder="Rationale"
          required // HTML5 form validation
        />
        <select 
          name="category"
          value={newTask.category}
          onChange={handleInputChange}
          required // HTML5 form validation
        >
          <option value="">Select Category</option>
          {categories.map(category => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
        <button type="submit">Add Issue</button>
      </form>
      
      <h3>Bugs and Incidents</h3>
      <ul className="tasks-list">
        {employee.tasks.map(task => (
          <li key={task.id} className={`score-${task.score}`}>
            <span className="title">  {task.name} </span><br/>
            <strong>{capitalize(task.type)}:</strong> <span style={{ color: getLinkColor(task.score) }}> {task.category} ({task.score}) </span> 
            <br/>
            <strong className="rationale">Rationale:</strong> <span style={{fontStyle: "italic", color: "#000"}}> {task.rationale} </span> <br/>
            <a href={task.link} target="_blank" rel="noopener noreferrer">View Task</a>
            <button onClick={() => handleDeleteTask(task.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EmployeeDetails;
