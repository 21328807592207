import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';  
import { get, ApiError } from 'aws-amplify/api';
import { Link } from 'react-router-dom';

import './EmployeesRank.css';

Amplify.configure(awsmobile);

// Function to determine the color based on the score
function getColorByScore(score) {
    if (score > 25) {
        return '#c0392b'; // Dark Red
    } else if (score >= 20 && score <= 24) {
        return '#d35400'; // Dark Purple
    } else if (score >= 15 && score <= 19) {
        return '#f39c12'; // Brown
    } else if (score >= 10 && score <= 14) {
        return '#2980b9'; // Olive
    } else {
        return '#2c3e50'; // Dark Blue for scores less than 10
    }
  }

const EmployeesList = () => {

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        async function fetchEmployees() {
            try {
                // Notice how the rest operation's response is awaited
                const restOperation = get({
                    apiName: 'employees', // Replace 'employeesApi' with your actual API name
                    path: '/employees'
                });
                const { body } = await restOperation.response; // Waiting for the promise to resolve
                const jsonResponse = await body.json();
                console.log(jsonResponse.period);
                // Example function to set period in local storage
                function setPeriodInLocalStorage(start_date, end_date) {
                    const period = JSON.stringify({ start_date, end_date });
                    localStorage.setItem('period', period);
                }
                
                // Example usage:
                setPeriodInLocalStorage(jsonResponse.period.start_date, jsonResponse.period.end_date);
                setEmployees(jsonResponse.data); // Adjust according to actual response structure
                setLoading(false);
            } catch (e) {
                console.log('GET call failed:', e);
                if (e instanceof ApiError && e.response) {
                    // Parsing the error message from response
                    const errorResponse = JSON.parse(e.response.body);
                    console.error('Error response:', errorResponse);
                    setError(`Error: ${errorResponse.message}`);
                } else {
                    setError('Failed to fetch employees');
                }
                setLoading(false);
            }
        }

        fetchEmployees();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="employees-container">
              <h2>Critical Performance Ranking</h2>
            <ul>
                {employees.map((employee, index) => (
                    <Link to={`/employee/${employee.id}`}>
                        <li key={employee.id} className="employee-item" style={{ color: getColorByScore(employee.score) }}>
                                {index + 1}. {employee.name} - {employee.score} points
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
};

export default EmployeesList;