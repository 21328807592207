import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './AccountabilityHeader.css';
import { color } from 'framer-motion';

const formatDate = (dateString) => {
  // Create a date object using the provided date string
  const date = new Date(dateString + 'T00:00:00'); // Append time to make sure it uses local midnight
  
  // Format the date in "d MMM, yyyy" format and ensure it uses the local time zone
  return date.toLocaleDateString('en-US', {
    day: '2-digit',
    month: 'short', // "short" month format (e.g., Jan, Feb, Mar)
    year: 'numeric',
    timeZone: 'America/New_York' // Specify the time zone if needed, or use 'UTC' if the server time is in UTC
  });
};

const calculateTimeLeft = (endDate) => {
  const difference = +new Date(endDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)), // Days without leading zeros
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24).toString().padStart(2, '0'),
      minutes: Math.floor((difference / 1000 / 60) % 60).toString().padStart(2, '0'),
      seconds: Math.floor((difference / 1000) % 60).toString().padStart(2, '0')
    };
  } else {
    timeLeft = {
      days: 0,
      hours: "00",
      minutes: "00",
      seconds: "00"
    };
  }

  return timeLeft;
};

const AccountabilityHeader = () => {
  const [period, setPeriod] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const periodString = localStorage.getItem('period');
    if (periodString) {
      try {
        const periodData = JSON.parse(periodString);
        periodData.start_date = formatDate(periodData.start_date);
        periodData.end_date = formatDate(periodData.end_date);
        console.log(periodData)
        setPeriod(periodData);
        setTimeLeft(calculateTimeLeft(periodData.end_date));
      } catch (error) {
        console.error('Error parsing period data from local storage:', error);
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (period && period.end_date) {
        setTimeLeft(calculateTimeLeft(period.end_date));
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, period]);

  return (
    <div className="document-header">
        <h2>Wehaa Accountability: A Quantitative Framework for Optimize the Performance of the Company</h2>
        <p>
            {/* Introduction to the Severity Scale<br/> */}
            To maintain the integrity of our work standards and accountability, Wehaa introduces a scale for categorization incidents and time trackings.
        </p>
        {period && period.start_date && period.end_date ? (
            <div className="header-flex"  style={{color:"#2c3e50"}}>
                <strong>Period: {period.start_date} to {period.end_date}</strong>
                <strong style={{color:"#191970"}}>Time left: {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s </strong>
            </div>
        ) : (
            <p>No reporting period set.</p>
        )}
    </div>
  );
}

export default AccountabilityHeader;
