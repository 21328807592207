
import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';  
import { get } from 'aws-amplify/api';
import { Link } from 'react-router-dom';

import './TimeTrackList.css';

Amplify.configure(awsmobile);

function getColorByCount(count) {
    if (count > 5) {
        return '#c0392b';
    } else if (count >= 4 && count <= 5) {
        return '#d35400';
    } else if (count == 3) {
        return '#f39c12';
    } else if (count >= 1 && count <= 2) {
        return '#2980b9';
    }
    return '#7f8c8d';
}

function MonitorList() {
    const [monitors, setMonitors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchMonitors();
    }, []);

    async function fetchMonitors() {
        try {
            const restOperation = await get({
                apiName: 'monitors',
                path: '/monitors'
            });

            const { body } = await restOperation.response; // Waiting for the promise to resolve
            const jsonResponse = await body.json();

            setMonitors(jsonResponse);
            setLoading(false);
        } catch (error) {
            setError('Error fetching monitors data');
            setLoading(false);
        }
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="times-container">
            <h2>Developer Workload</h2>
            <p>Overview of all tasks currently in progress across the team, providing insight into each developer's active workload.</p>
            <ul>
                {monitors.map((monitor, index) => (
                    <Link to={`/monitors/${monitor.user_id}`}>
                    <li
                        key={index}
                        className="time-item"
                        style={{ color: getColorByCount(monitor.count) }}
                    >
                            ► <strong> {monitor.username} </strong>
                         <p> ○ Tasks in progress: {monitor.count} </p> 
                    </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
}

export default MonitorList;
