/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "categories",
            "endpoint": "https://n2tt915637.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "employees",
            "endpoint": "https://hvttho32q9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "incidents",
            "endpoint": "https://pv5w671dl0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "monitors",
            "endpoint": "https://rjwrjed346.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "spaces",
            "endpoint": "https://y78il23omc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "testing",
            "endpoint": "https://dzhz1h5eg3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "times",
            "endpoint": "https://jey77zznmc.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:0ef585d5-fbf6-4e71-9a2d-322d99be00dd",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XnOMDHC7S",
    "aws_user_pools_web_client_id": "3dtga9p7ga9fnmp4ntjd0fft0o",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "wehaacaps-hosting-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1dtrwu5wiw06q.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "caps-categories-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "caps-employees-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "caps-incidents-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "incidents-categories-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "caps-periods-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "testing-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
