
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';
import { get } from 'aws-amplify/api';
import './EmployeeDetails.css';

Amplify.configure(awsmobile);

function capitalize(str) {
  if (!str || typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function formatDuration(ms) {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function MonitorDetail() {
  const { user_id } = useParams();
  const [monitor, setMonitor] = useState([]);
  const [userData, setUserData] = useState(null);
  const [totalTasks, setTotalTasks] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMonitorData = async (user_id) => {
      try {
        const restOperation = get({
          apiName: 'monitors',
          path: `/monitors/${user_id}`
        });
        const { body } = await restOperation.response;
        const jsonResponse = await body.json();

        if (jsonResponse.length > 0) {
          setUserData({"username" : jsonResponse[0].username, "email" : jsonResponse[0].email} );
          setMonitor(jsonResponse);

          let tasks = 0;
          jsonResponse.forEach(entry => {
            tasks++;
          });
          setTotalTasks(tasks);
        } else {
          setError('No monitor entries found');
        }

        setLoading(false);
      } catch (err) {
        console.error('Fetching monitor data failed:', err);
        setError('Failed to fetch monitor data');
        setLoading(false);
      }
    };

    fetchMonitorData(user_id);
  }, [user_id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!userData) return <p>No user data found</p>;

  return (
    <div className="employee-content">
      <h2>{userData.username} - <span>{userData.email}</span></h2>
      <h3>Current Workload: {totalTasks} {totalTasks === 1 ? 'Task' : 'Tasks'}</h3>
      <h3>Detailed Task List</h3>
      <ul className="tasks-list">
        {monitor.map(task => (
          <li key={task.id} className="task-item">
            <span className="title">{task.name}</span><br/>
            <strong>Status:</strong> <span style={{ color: task.status }}>{capitalize(task.status)}</span><br/>
            <strong>Type:</strong> {capitalize(task.type)}<br/>
            <strong>Priority:</strong> {capitalize(task.priority)}<br/>
            {task.description && (
              <>
                <strong>Description:</strong> {task.description}<br/>
              </>
            )}
            <a href={task.url} target="_blank" rel="noopener noreferrer">View Task</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MonitorDetail;
