import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { get, ApiError } from 'aws-amplify/api';
import awsmobile from '../aws-exports';  

Amplify.configure(awsmobile);

function pluralize(count, word) {
    return count === 1 ? `${count} ${word}` : `${count} ${word}s`;
}
  

const CategoriesComponent = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                // Assuming your API name and path are set correctly in aws-exports.js
                const restOperation = await get({ apiName: 'categories', path: '/categories' });
                const { body } = await restOperation.response; 
                const jsonResponse = await body.json();
                setCategories(jsonResponse); 
                setLoading(false);
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to fetch categories');
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <h2>Categories Severity Scale</h2>
            <ul>
                {categories.map(category => (
                    <li key={category.id}>{category.name} ({pluralize(category.points, "point")})</li>
                ))}
            </ul>
        </div>
    );
};

export default CategoriesComponent;
