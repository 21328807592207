import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';  
import { get, ApiError } from 'aws-amplify/api';
import { Link } from 'react-router-dom';

import './TimeTrackList.css';

Amplify.configure(awsmobile);

// Function to capitalize the first letter of each word
function capitalize(str) {
    return str
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase());
}

// Function to determine the color based on the hours category
function getColorByCategory(category) {
    switch (category.toLowerCase()) {
        case 'low':
            return '#e67e22'; // Orange
        case 'very low':
            return '#e74c3c'; // Red
        case 'normal':
            return '#3498db'; // Blue
        case 'high':
            return '#27ae60'; // Green
        case 'very high':
            return '#2ecc71'; // Light Green
        default:
            return '#2c3e50'; // Dark Blue as a default
    }
}

const TimeTrackList = () => {
    const [times, setTimes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        async function fetchTimes() {
            try {
                const restOperation = get({
                    apiName: 'times', // Replace 'timesApi' with your actual API name
                    path: '/times'
                });
                const { body } = await restOperation.response;
                const jsonResponse = await body.json();

                // Assuming the response is an array, not an object with a `data` field
                setTimes(jsonResponse); 
                setLoading(false);
            } catch (e) {
                console.log('GET call failed:', e);
                if (e instanceof ApiError && e.response) {
                    const errorResponse = JSON.parse(e.response.body);
                    console.error('Error response:', errorResponse);
                    setError(`Error: ${errorResponse.message}`);
                } else {
                    setError('Failed to fetch times');
                }
                setLoading(false);
            }
        }

        fetchTimes();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="times-container">
            <h2>Worked Times Overview - This Week</h2>
            <ul>
                {times.map((time, index) => (
                    <Link to={`/times/${time.id}`}>
                    <li
                        key={index}
                        className="time-item"
                        style={{ color: getColorByCategory(time.hours_category) }}
                    >
                            ► {time.name} - {time.worked_time} hours ({capitalize(time.hours_category)})
                        <p> ○ Bug Time: {time.type_bug_time} ({time.type_bug_time_percentage})</p>
                    </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
};

export default TimeTrackList;
