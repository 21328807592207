import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';  
import { get } from 'aws-amplify/api';
import './EmployeeDetails.css';

Amplify.configure(awsmobile);

// Function to capitalize the first letter of each word
function capitalize(str) {
  if (!str || typeof str !== 'string') return ''; // Check if the string is valid
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Function to format duration from milliseconds to hh:mm:ss
function formatDuration(ms) {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

function TimeDetails() {
  const { id } = useParams();  // Get the employee ID from the URL
  const [time, setTime] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [totalDuration, setTotalDuration] = useState(0);
  const [totalBugDuration, setTotalBugDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch employee details by ID
    const fetchTimeById = async (id) => {
      try {
        const restOperation = get({
            apiName: 'times', 
            path: `/times/${id}`
        });
        const { body } = await restOperation.response; 
        const jsonResponse = await body.json();

        // Assuming jsonResponse is an array of time entries
        if (jsonResponse.length > 0) {
          setEmployee(jsonResponse[0].user); // Set employee info from the first entry
          setTime(jsonResponse);

          // Calculate total duration and total bug duration
          let total = 0;
          let totalBugs = 0;
          jsonResponse.forEach(entry => {
            const duration = parseInt(entry.duration, 10);
            total += duration;

            if ([1001, 1010].includes(entry.task.custom_type)) {
              totalBugs += duration;
            }
          });

          setTotalDuration(total);
          setTotalBugDuration(totalBugs);
        } else {
          setError('No time entries found');
        }

        setLoading(false);
      } catch (err) {
        console.error('Fetching time failed:', err);
        setError('Failed to fetch time');
        setLoading(false);
      }
    };

    fetchTimeById(id);
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!employee) return <p>No employee data found</p>;

  return (
    <div className="employee-content">
      <h2>{employee.username} - <span>{employee.email}</span></h2>
      <h3>Total Duration: {formatDuration(totalDuration)}</h3>
      <h3>Total Bug/Incident Duration: {formatDuration(totalBugDuration)}</h3>
      <h3>Entries Details</h3>
      <ul className="tasks-list">
        {time.map(entry => {
          let taskType = '';
          if (entry.task.custom_type === 1001) {
            taskType = 'Bug';
          } else if (entry.task.custom_type === 1010) {
            taskType = 'Incidence';
          }

          return (
            <li key={entry.id} className="task-item">
              <span className="title">{entry.task.name}</span><br/>
              <strong>Status:</strong> <span style={{ color: entry.task.status.color }}>{capitalize(entry.task.status.status)}</span><br/>
              <strong>Duration:</strong> {formatDuration(parseInt(entry.duration, 10))}<br/>
              {taskType && (
                <>
                  <strong>Type:</strong> {taskType}<br/>
                </>
              )}
              <a href={entry.task_url} target="_blank" rel="noopener noreferrer">View Task</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TimeDetails;
