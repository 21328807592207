import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, NavLink, Routes } from 'react-router-dom';
import './App.css';
import EmployeesList from './components/EmployeesList';
import CategoriesCRUD from './components/CategoriesCRUD';
import AccountabilityHeader from './components/AccountabilityHeader';
import EmployeeDetails from './components/EmployeeDetails';
import TimeDetails from './components/TimeDetails';
import IncidentsCRUD from './components/IncidentsCRUD';
import TimeTrackList from './components/TimeTrackList';
import SpaceTrackList from './components/SpaceTrackList';
import SpaceDetails from './components/SpaceDetails';
import MonitorList from './components/MonitorList';
import MonitorDetail from './components/MonitorDetail';

import { Authenticator, Button, useTheme, View, Image, Heading, useAuthenticator, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// Helper function to set the document title
const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const components = {
  Header() {
    const { tokens } = useTheme();
    useDocumentTitle("Accountability - AI Wehaa");
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Wehaa"
          src="https://delta.creativecirclecdn.com/newspapers/original/20220206-165100-Wehaa%20logo.jpg"
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={4}
        >
          Sign in AI Wehaa Accountability
        </Heading>
      );
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toForgotPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ForgotPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

function App() {
  return (
    <Authenticator hideSignUp components={components}>
      {({ signOut, user }) => (
        <Router>
          <div className="App">
            <div className="sidebar">
              <h1 className="sidebar-title">AI Wehaa</h1>
              <nav>
                <ul>
                  <li><NavLink to="/" end style={({ isActive }) => isActive ? { backgroundColor: '#dedede', color: '#000' } : null}>Employees</NavLink></li>
                  <li><NavLink to="/monitors" style={({ isActive }) => isActive ? { backgroundColor: '#dedede', color: '#000' } : null}>Developer Workload</NavLink></li>
                  <li><NavLink to="/times" style={({ isActive }) => isActive ? { backgroundColor: '#dedede', color: '#000' } : null}>Time Tracking</NavLink></li>
                  <li><NavLink to="/spaces" style={({ isActive }) => isActive ? { backgroundColor: '#dedede', color: '#000' } : null}>Time on Spaces</NavLink></li>
                  <li><NavLink to="/incidents" style={({ isActive }) => isActive ? { backgroundColor: '#dedede', color: '#000' } : null}>Incidents</NavLink></li>
                  <li><NavLink to="/categories" style={({ isActive }) => isActive ? { backgroundColor: '#dedede', color: '#000' } : null}>Categories</NavLink></li>
                  <li><span onClick={signOut} style={{ margin: '12px', cursor: 'pointer' }}><strong>Sign Out</strong></span></li>
                </ul>
              </nav>
            </div>
            <div className="content">
              <header className="App-header">
                <AccountabilityHeader />
              </header>
              <Routes>
                <Route 
                  path="/" 
                  element={<EmployeesListWithTitle />} 
                />
                <Route 
                  path="/monitors" 
                  element={<MonitorListWithTitle />} 
                />
                <Route 
                  path="/times" 
                  element={<TimeTrackListWithTitle />} 
                />
                <Route 
                  path="/spaces" 
                  element={<SpaceTrackListWithTitle />} 
                />
                <Route 
                  path="/incidents" 
                  element={<IncidentsCRUDWithTitle />} 
                />
                <Route 
                  path="/categories" 
                  element={<CategoriesCRUDWithTitle />} 
                />
                <Route 
                  path="/employee/:id" 
                  element={<EmployeeDetailsWithTitle />} 
                />
                <Route 
                  path="/monitors/:user_id" 
                  element={<MonitorDetailWithTitle />} 
                />
                <Route 
                  path="/times/:id" 
                  element={<TimeDetailsWithTitle />} 
                />
                <Route 
                  path="/spaces/:id" 
                  element={<SpaceDetailsWithTitle />} 
                />
              </Routes>
            </div>
          </div>
        </Router>
      )}
    </Authenticator>
  );
}

// Wrap each component to set the document title

const EmployeesListWithTitle = () => {
  useDocumentTitle("Employees - AI Wehaa");
  return <EmployeesList />;
};

const MonitorListWithTitle = () => {
  useDocumentTitle("Developer Workload - AI Wehaa");
  return <MonitorList />;
};

const MonitorDetailWithTitle = () => {
  useDocumentTitle("Workload Detail - AI Wehaa");
  return <MonitorDetail />;
};

const TimeTrackListWithTitle = () => {
  useDocumentTitle("Time Tracking - AI Wehaa");
  return <TimeTrackList />;
};

const SpaceTrackListWithTitle = () => {
  useDocumentTitle("Time on Spaces - AI Wehaa");
  return <SpaceTrackList />;
};

const IncidentsCRUDWithTitle = () => {
  useDocumentTitle("Incidents - AI Wehaa");
  return <IncidentsCRUD />;
};

const CategoriesCRUDWithTitle = () => {
  useDocumentTitle("Categories - AI Wehaa");
  return <CategoriesCRUD />;
};

const EmployeeDetailsWithTitle = () => {
  useDocumentTitle("Employee Details - AI Wehaa");
  return <EmployeeDetails />;
};

const TimeDetailsWithTitle = () => {
  useDocumentTitle("Time Details - AI Wehaa");
  return <TimeDetails />;
};

const SpaceDetailsWithTitle = () => {
  useDocumentTitle("Space Details - AI Wehaa");
  return <SpaceDetails />;
};

export default App;
