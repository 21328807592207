import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';
import { get, post, put, del, ApiError } from 'aws-amplify/api';
import { 
    Accordion, 
    AccordionItem, 
    AccordionButton, 
    AccordionPanel, 
    AccordionIcon, 
    Box, 
    Input, 
    Button, 
    Select, 
    Heading, 
    HStack
} from '@chakra-ui/react';
import './IncidentsCRUD.css'; // Make sure to import your custom CSS file

Amplify.configure(awsmobile);

const IncidentsCRUD = () => {
    const [incidents, setIncidents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({ name: '', category_id: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [currentIncidentId, setCurrentIncidentId] = useState(null);

    const categories = [
        { id: 5, name: 'Critical' },
        { id: 2, name: 'Moderate' },
        { id: 3, name: 'Severe' },
        { id: 1, name: 'Normal' },
        { id: 4, name: 'Very Serious' },
    ];

    useEffect(() => {
        async function fetchIncidents() {
            try {
                const restOperation = get({
                    apiName: 'incidents', // Replace with your API name
                    path: '/incidents/categories'
                });
                const { body } = await restOperation.response;
                const jsonResponse = await body.json();
                setIncidents(jsonResponse);
                setLoading(false);
            } catch (e) {
                handleError(e);
            }
        }

        fetchIncidents();
    }, []);

    const handleError = (e) => {
        console.log('API call failed:', e);
        if (e instanceof ApiError && e.response) {
            const errorResponse = JSON.parse(e.response.body);
            setError(`Error: ${errorResponse.message}`);
        } else {
            setError('Failed to perform operation');
        }
        setLoading(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCategoryChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex].text;
        
        setFormData({ 
            ...formData, 
            category_id: e.target.value,
            category_name: selectedOption // If you want to save the name as well
        });
    };

    const handleCreateIncident = async () => {
        try {
            console.log('Creating incident:', formData);
            
            const restOperation = post({
                apiName: 'incidents',
                path: '/incidents/categories',
                options: {
                    body: formData
                }
            });
            const { body } = await restOperation.response;
            console.log('Incident created:', body);
            const newIncident = await body.json();
            setIncidents([...incidents, newIncident.item]);
            resetForm();
        } catch (e) {
            handleError(e);
        }
    };
    

    const handleEditIncident = (incident) => {
        setFormData({ name: incident.name, category_id: incident.category_id });
        setIsEditing(true);
        setCurrentIncidentId(incident.id);
    };

    const handleUpdateIncident = async () => {
        try {
            const restOperation = put({
                apiName: 'incidents', // Replace with your API name
                path: `/incidents/categories/${currentIncidentId}`,
                options: {
                    body: formData
                }
            });
            const { body } = await restOperation.response;
            const updatedIncident = await body.json();
    
            // Remove the old incident and add the updated one
            setIncidents(prevIncidents => {
                const filteredIncidents = prevIncidents.filter(incident => incident.id !== currentIncidentId);
                return [...filteredIncidents, updatedIncident.item];
            });
    
            resetForm();
        } catch (e) {
            handleError(e);
        }
    };
    

    const handleDeleteIncident = async (id) => {
        try {
            await del({
                apiName: 'incidents', // Replace with your API name
                path: `/incidents/categories/${id}`
            });
            setIncidents(incidents.filter(incident => incident.id !== id));
        } catch (e) {
            handleError(e);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEditing) {
            handleUpdateIncident();
        } else {
            handleCreateIncident();
        }
    };

    const resetForm = () => {
        setFormData({ name: '', category_id: '' });
        setIsEditing(false);
        setCurrentIncidentId(null);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    // Group incidents by category
    const groupedIncidents = incidents.reduce((acc, incident) => {
        const category = categories.find(cat => cat.id === parseInt(incident.category_id))?.name || 'Uncategorized';
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(incident);
        return acc;
    }, {});

    return (
        <div className="incidents-container">
            <Heading as="h2" size="lg" mb={4}>AI Machine Learning - Incidents Management </Heading>

            <form onSubmit={handleSubmit} className="incident-form">
                <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Incident Name"
                    required
                    mb={2}
                />
                <Select
                    placeholder="Select Category"
                    value={formData.category_id}
                    onChange={handleCategoryChange}
                    required
                    mb={4}
                >
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Select>
                <HStack spacing={4}>
                    <Button type="submit" colorScheme="blue">
                        {isEditing ? 'Update Incident' : 'Create Incident'}
                    </Button>
                    {isEditing && (
                        <Button onClick={resetForm} colorScheme="gray">
                            Cancel
                        </Button>
                    )}
                </HStack>
            </form>

            <Accordion allowMultiple mt={6} className="accordion">
                {Object.keys(groupedIncidents).map(category => (
                    <AccordionItem key={category}>
                        <h2>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    {category} ({groupedIncidents[category].length} Incidents)
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <ul>
                                {groupedIncidents[category].map((incident) => (
                                    <li key={incident.id} className="incident-item">
                                        <Box mb={2}>
                                            <strong>➤ Incident:</strong> {incident.name}
                                        </Box>
                                        <Button onClick={() => handleEditIncident(incident)} colorScheme="yellow" mr={2}>Edit</Button>
                                        <Button onClick={() => handleDeleteIncident(incident.id)} colorScheme="red">Delete</Button>
                                    </li>
                                ))}
                            </ul>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
};

export default IncidentsCRUD;
