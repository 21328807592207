import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';  
import { get, ApiError } from 'aws-amplify/api';
import { Link } from 'react-router-dom';

import './SpaceTrackList.css';

Amplify.configure(awsmobile);

// Function to convert HH:MM:SS to total seconds
function convertTimeToSeconds(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
}

const SpaceTrackList = () => {
    const [spaces, setSpaces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        async function fetchSpaces() {
            try {
                const restOperation = get({
                    apiName: 'spaces', // Replace 'spacesApi' with your actual API name
                    path: '/spaces'
                });
                const { body } = await restOperation.response;
                const jsonResponse = await body.json();

                // Sort the spaces by worked_time in descending order
                const sortedSpaces = jsonResponse.sort((a, b) => {
                    const timeA = convertTimeToSeconds(a.worked_time);
                    const timeB = convertTimeToSeconds(b.worked_time);
                    return timeB - timeA;
                });

                setSpaces(sortedSpaces);
                setLoading(false);
            } catch (e) {
                console.log('GET call failed:', e);
                if (e instanceof ApiError && e.response) {
                    const errorResponse = JSON.parse(e.response.body);
                    console.error('Error response:', errorResponse);
                    setError(`Error: ${errorResponse.message}`);
                } else {
                    setError('Failed to fetch spaces');
                }
                setLoading(false);
            }
        }

        fetchSpaces();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="spaces-container">
            <h2>Spaces Overview - This Week</h2>
            <ul>
                {spaces.map((space, index) => (
                    <Link to={`/spaces/${space.id}`} key={index}>
                        <li className="space-item">
                             <strong> {index+1}. {space.name} - {space.worked_time} hours </strong>
                            <p>► Bug Time: {space.type_bug_time} ({space.type_bug_time_percentage})</p>
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
};

export default SpaceTrackList;
